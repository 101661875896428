<template>
    <div class="top-section">
        <div class="welcome">
            <span>welcome to my workspace</span>
        </div>
        <div class="weather">
            <div v-if="weather">
                <img class="icon" :src="`https://cdn.buienradar.nl/resources/images/icons/weather/116x116/${weather.icon}.png`" alt>
                <table class="data">
                    <tr class="statistic" v-if="weather.temperature">

                        <th class="value" v-if="fahrenheit">{{ ((weather.temperature * 9 + 160) / 5).toFixed(1) }}</th>
                        <th class="value" v-else>{{ weather.temperature }}</th>

                        <th class="unit" v-if="fahrenheit">&deg;F</th>
                        <th class="unit" v-else>&deg;C</th>

                    </tr>
                    <tr class="statistic" v-if="weather.pressure">
                        <th class="value">{{weather.pressure}}</th>
                        <th class="unit">hPa</th>
                    </tr>
                    <tr class="statistic" v-if="weather.humidity">
                        <th class="value">{{weather.humidity}}</th>
                        <th class="unit">%</th>
                    </tr>
                    <tr class="statistic" v-if="weather.windspeed">
                        <th class="value">{{weather.windspeed}}</th>
                        <th class="unit">Bft</th>
                    </tr>
                </table>
                <div class="location">
                    <span>The Hague, NL</span>
                    <span class="time"><active-time></active-time></span>
                </div>
            </div>
            <span v-else>Loading...</span>
        </div>
        <div class="navigation">
            <ul class="items">
                <li v-for="link in navigation">
                    <sliding-url :to="link.to">{{link.name}}</sliding-url>
                </li>
            </ul>
        </div>
    </div>
    <div class="content">
        <router-view/>
    </div>
    <div class="copyright">
        <div class="credits">2014-{{ currentYear }} ~justeli.me</div>
        <div>&copy;</div>
    </div>
</template>

<script>
import SlidingUrl from '@/components/SlidingUrl.vue'
import axios from 'axios'
import ActiveTime from '@/components/ActiveTime.vue'

export default {
    name: 'App',
    components: {
        ActiveTime,
        SlidingUrl
    },
    data() {
        return {
            weather: null,
            currentYear: new Date().getFullYear(),
            fahrenheit: false,
            navigation: [
                { name: 'home', to: '/' },
                { name: 'projects', to: '/projects' },
                { name: 'gists', to: '/gists' },
                { name: 'tools', to: '/tools' },
                { name: 'donate', to: 'https://donate.stripe.com/fZe8y1bxr8EV7cYdQQ'},
                { name: 'split-flap', to: 'https://splitflap.justeli.me/?2=+++++WELCOME&3=++++++TO+MY&5=++++SPLITFLAP&6=+++++DISPLAY'},
                // { name: 'blog', to: '/blog' },
                // { name: 'code', to: '/code' },
                // { name: 'screenshots', to: '/screenshots' },
            ]
        }
    },
    methods: {
        updateWeather () {
            axios.get('https://observations.buienradar.nl/1.0/actual/weatherstation/6215').then(response => {
                this.weather = {
                    temperature: response.data.temperature,
                    pressure: Math.round(response.data.airpressure),
                    humidity: Math.round(response.data.humidity),
                    windspeed: response.data.windspeedBft,
                    icon: response.data.iconcode
                }
            })
        }
    },
    created() {
        this.updateWeather()
        setInterval(this.updateWeather, 60000)

        if (window.navigator.language === 'en-US' || window.navigator.language === 'my') {
            this.fahrenheit = true
        }
    },
    mounted() {
        const background = require(`@/media/wood/${Math.floor(Math.random() * 27)}-Low.jpg`)
        document.getElementsByClassName('top-section')[0].style.background = `url('${background}') fixed top center`
    }
}
</script>

<style scoped>
.top-section {
    z-index: 10001;
    overflow: hidden;
    background-color: #4e342e;
}

.top-section .welcome {
    padding: 38px;
    display: flex;
    justify-content: center;
    font-size: 72px;
    font-family: Handwriting, sans-serif;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px #171717;
}

.content {
    margin: 38px auto;
    padding: 0 38px;
    max-width: 1200px;
}

.navigation {
    padding: 0.7em;
    text-align: center;
    background: rgba(10, 10, 10, 0.8);
}

.navigation ul {
    padding: 0;
    margin: 0;
}

.navigation ul li {
    font-family: Raisonne, sans-serif;
    font-size: 1.5em;
    z-index: 10002;
    display: inline;
    position: relative;
    text-shadow: 0 0 3px rgb(120, 137, 185);
    overflow: visible;
    padding: 0 15px;
}

.weather
{
    font-family: Raisonne, sans-serif;
    z-index: 10002;
    right: 8px;
    top: 8px;
    position: absolute;
    text-shadow: 1px 1px 3px #171717;
    text-align: center;
    background: rgba(1, 1, 1, 0.3);
    border-radius: 10px;
    padding: 8px;
    overflow: hidden;
    line-height: 0.9;
}

.weather .icon {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
}

.weather .data {
    margin: 0 auto;
}

.weather .data .statistic {
    font-size: 23px;
}

.weather .data .value {
    font-family: Handwriting, sans-serif;
    font-size: 32px;
    text-align: right;
}

.weather .data .unit {
    text-align: left;
}

.weather .location {
    margin-top: 10px;
    line-height: 1.2;
    font-size: 0.8em;
    color: #dcd5cb;
}

.weather .location .time {
    display: block;
    font-family: RelativeMono, monospace;
}

.copyright
{
    left: 10px;
    bottom: 5px;
    position: fixed;
    font-size: 1.5em;
    font-family: Handwriting, Raisonne, sans-serif;
    text-align: center;
}

.copyright .credits {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    margin-bottom: 5px;
}
</style>