<template>
    {{time}}
</template>

<script>
export default {
    name: 'ActiveTime',
    data()
    {
        return {
            time: this.getTime()
        }
    },
    methods: {
        getTime ()
        {
            return new Date().toLocaleTimeString([], {timeZone: 'Europe/Amsterdam' })
        }
    },
    created()
    {
        setInterval(() =>
        {
            this.time = this.getTime()
        }, 1000)
    }
}
</script>

<style scoped>

</style>