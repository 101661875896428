<template>
    <span>
        <router-link v-if="!to.startsWith('https://')" class="underline-effect" :to="to">
            <slot></slot>
        </router-link>
        <a v-else class="underline-effect" :href="to">
            <slot></slot>
        </a>
    </span>
</template>

<script>
export default {
    name: 'InlineUrl',
    props: {
        to: String
    }
}
</script>

<style scoped>
/* Sliding Underline */
.underline-effect::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    left: 0;
    bottom: -1px;
    background: #ad1457;
    transition: width .2s ease;
}

.underline-effect:hover:before {
    width: 100%;
}

.underline-effect {
    position: relative;
}
</style>