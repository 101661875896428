import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index.js'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'home', component: () => import('@/views/Home.vue') },
        { path: '/projects', name: 'projects', component: () => import('@/views/Projects.vue') },
        { path: '/gists', name: 'gists', component: () => import('@/views/gists/Gists.vue') },
        { path: '/gists/:id', name: 'gist', component: () => import('@/views/gists/Gist.vue') },
        { path: '/tools', name: 'tools', component: () => import("@/views/Tools.vue") },
        { path: '/tool/java/functional-lambda', name: 'Java: Functional Lambda', component: () => import("@/views/tools/JavaLambda.vue") },
        { path: '/tool/sql/datatype', name: 'SQL: Datatype', component: () => import("@/views/tools/SqlDatatype.vue") },
        // { path: '/blog', name: 'blog', component: () => import('@/views/Blog.vue') },
        // { path: '/code', name: 'code', component: () => import('@/views/Code.vue') },
        // { path: '/screenshots', name: 'screenshots', component: () => import('@/views/Screenshots.vue') },
        { path: '/:catchAll(.*)', name: '404', component: () => import('@/views/errors/404.vue') }
    ]
})

let current = 'x'
let loadingAssets = false

router.beforeEach((to, from, next) => {
    // loading .css and .js files
    if (current !== to.href && !loadingAssets)
    {
        store.commit('startLoading')
        current = to.href
        loadingAssets = true
    }
    next()
})

router.afterEach(to => {
    // finished loading .css and .js files
    if (loadingAssets)
    {
        store.commit('finishLoading')
        loadingAssets = false
    }

    // change path title
    const path = to.path === '/'? '/home' : to.path;
    document.title = `${path} · justeli.me`
})

export default router