import { createApp } from 'vue'
import App from '@/App.vue'

import router from '@/router'
import store from '@/store'

import '@/registerServiceWorker'

// router
import '@/router/loader'
import '@/router/http.js'

// global settings
import '@/assets/fonts.css'
import '@/assets/global.css'

// plugin themes
import '@/assets/nprogress/nprogress-theme.css'
import '@/assets/prismjs/prism-theme.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')