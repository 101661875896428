import store from '@/store'
import NProgress from 'nprogress'

NProgress.configure({ showSpinner: false })

store.watch(state => state.loading, (processesLeft, oldValue) =>
{
    if (processesLeft === 0) return NProgress.done()
    if (oldValue === 0) return NProgress.start()
    if (oldValue > processesLeft) NProgress.inc()

    //NProgress.set(1.6 / Math.max(processesLeft, oldValue))
})